/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply !bg-white;
  }

  body {
    @apply overflow-x-hidden bg-white font-Poppins text-gray-800;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  .shemsiBtn {
    @apply flex items-center justify-center rounded-sm border border-transparent px-8 py-3 text-base font-medium text-white shadow-sm focus:ring-2 focus:ring-gray-900 focus:ring-offset-2;
  }
  .shemsiBtn-primary {
    @apply bg-black;
  }
  .shemsiBtn-reverse {
    @apply border border-gray-900 bg-white text-gray-900;
  }
  .shemsiBtn-secondary {
    @apply bg-beige-900;
  }
  .shemsiBtn-hover:not(:disabled) {
    @apply transition duration-200 ease-in-out hover:-translate-y-0.5 hover:shadow-button hover:!shadow-beige-900;
  }
  .shemsiBtn-hover-reverse {
    @apply border hover:border-beige-900 hover:bg-white hover:text-beige-900;
  }
  .title-1 {
    @apply text-2xl font-bold tracking-tight lg:text-3xl;
  }
  .title-2 {
    @apply mb-4 text-xl font-bold lg:text-2xl;
  }
  .title-3 {
    @apply mb-2 text-lg font-bold lg:text-xl;
  }
  .sizing-grid > div {
    @apply flex items-center justify-center py-3.5;
  }
  .sizing-grid > div:nth-child(-n + 4) {
    @apply bg-beige-200;
  }
  .sizing-grid > div:nth-child(n + 5) {
    @apply bg-beige-50;
  }
  .success-message {
    @apply mt-2 w-full rounded-sm border border-green-300 bg-green-200 px-3 py-1 text-start text-sm font-semibold shadow;
  }
  .error-message {
    @apply mt-2 w-full rounded-sm border border-red-300 bg-red-200 px-3 py-1 text-start text-sm font-semibold shadow;
  }
  .info-message {
    @apply mt-2 w-full rounded-sm border border-yellow-500 bg-yellow-100 px-3 py-1 text-start text-sm font-semibold shadow;
  }
  .input-text {
    @apply w-full appearance-none rounded-sm border border-gray-300 bg-white px-4 py-2 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-gray-700 focus:outline-none focus:ring-1 focus:ring-gray-700;
  }
  .input-text-error {
    @apply w-full appearance-none rounded-sm border border-red-400 bg-white px-4 py-2 text-base text-gray-900 shadow-sm focus:border-red-700 focus:outline-none focus:ring-1 focus:ring-red-600;
  }
  .error-text {
    @apply text-sm font-medium text-red-800;
  }
  .discount-tag {
    @apply flex items-center gap-1 rounded-xl border border-green-600 bg-green-100 p-1 text-xs text-gray-900;
  }
}

.gm-style-iw-c {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
}

.collection_filter_Size .theme-select__multi-value {
  text-transform: uppercase;
}

.theme-select__control:hover {
  border-color: rgb(209, 213, 219) !important;
}

.theme-select__input {
  box-shadow: none !important;
}

.list {
  @apply mb-2 ms-6 list-disc;
}

.text {
  @apply mb-2;
}

.adjacent-items {
  @apply flex flex-col gap-4 lg:flex-row;
}

/* .expandable-text {
  width: 10em;
  height: 3em;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  background: #ccc;
  background-color: lightgreen;
  border-radius: 8px;
  border: 2px solid #6c6;
  transition: all 2s ease-in-out;
} */
.text-default {
  width: fit-content;
  max-width: 20rem;
  max-height: 2rem;
  overflow: hidden;
  /* transition: all linear 2000ms; */
  transition: all 1s ease-in-out;
}
.text-default:hover {
  transition: all 1s ease-in-out;
  max-width: 20rem;
  max-height: 10rem;
}
/* .text-expanded { */
/* width: 20rem;
  max-height: 10rem; */
/* overflow: hidden;
  transition: all linear 2000ms; */
/* background: rgb(30, 9, 90); */
/* } */
/* .expandable-text {
  transition: all 5s linear;
  transition: all linear 5s;
} */

.text-xxs {
  @apply text-[10px] leading-[8px];
}

/* width */
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}
